
// ======================== Development URL ==============================

// export const  BASE_URL= "https://api.webinar.rbstaging.in/"; //devlopment//



// ======================== Production URL ==============================



export const BASE_URL = 'https://api.webinar.renewbuy.com/'  //production//
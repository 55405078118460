import React, { useEffect, useState } from "react";
import InputSearch from "../../../Components/commoncomponent/InputSearch";
import { useNavigate } from "react-router-dom";
import filter from "../../../Assets/image/icons/calendar.png";
import Cheakerproduct from "../../../Components/commoncomponent/Cheakerprodect";
import collertal from "../../../Assets/image/Rbstore/collertal.png";
import ApprovedCart from "../../../Components/commoncomponent/ApprovedCart";
import Calender from "../../../Components/commoncomponent/Calender";
import { getallwebinar } from "../../../Utils/services";
import Spinner from '../../../Components/commoncomponent/Spinner'
import nodata from "../../../Assets/image/icons/nodata.svg"

const PastApproved = () => {
  const [exc, setexc] = useState([])
  const [data1, setdata1] = useState([])

  const [loading, setLoading] = useState(true)

  const navigate = useNavigate();
  const handleCollateralpage = (e) => {
    navigate('/approved')
  };

  const [show, setShow] = useState(false)
  const handleshowclick = () => {
    setShow(!show)
  }
  const formData = new FormData();
  const [start, setstart] = useState(null);
  const [end, setend] = useState(null);
  const filter = () => {
    singleuser()
  }

  const singleuser = async () => {
    formData.append("pageSize", 10);
    formData.append("type", 1);
    formData.append("approvalType", 1);
    formData.append("pageNumbe", 0);
    if (start !== null) {
      formData.append("startDate", start);
    };
    if (end !== null) {
      formData.append("endDate", end);
    }

    const result = await getallwebinar(formData)
    setexc(result.res.data.webinars)
    setdata1(result.res.data.webinars)
    setLoading(false)

  }

  useEffect(() => {
    singleuser()
  }, [])


  const handleSearch = (event) => {
    const searchValue = event.target.value;
    const filterArray = exc.filter((e) => {
      return e.title.toLowerCase().includes(searchValue.toLowerCase()) || e.host.toLowerCase().includes(searchValue.toLowerCase())
    }
    );
    setdata1(filterArray);
  };


  return (
    <div>
      <div className="container-fuild">
        <div className=" row ">
          <div className=" align-items-center justify-content-between searchingbox">
              <div className='searchInput'>
              <input type="text" placeholder='Search Collaterals' onChange={handleSearch} />
            </div>
            <div className=" dis-flex button-Calender">
              <button class="d-flex align-items-center justify-content-center  excle-exportpart px-0 " onClick={() => handleshowclick()}>
                <p className="me-2">Choose Date</p> <img src={filter} alt="" />{" "}
              </button>
              {show ?
                <div className="">
                  <Calender filter={filter} start={start} setstart={setstart} end={end} setend={setend} setShow={setShow}/>

                </div> : ""
              }
            </div>
          </div>
        </div>
        <div className="productswitch mt-3">
          <Cheakerproduct
            collaterals="productactive"
            handleMarchant={handleCollateralpage}
          />
        </div>
        <div className="row mt-5 " style={{ justifyContent: 'space-around' }}>


          {loading ? <Spinner /> :
            <>
              {data1.length !== 0 ?
                data1.map((val) => {
                  return (
                    <div className="col-xxl-5 col-xl-6 col-lg-6  col-md-6 col-sm-6 col-12 mb-5">
                      <ApprovedCart data={val} image={val.myImagePath} collertalimg={collertal} Title={val.title} webinarOn={val.webinarOn} Language={val.langName} webId={val.webId} />

                    </div>)
                }) :
                <div className="d-flex align-items-center justify-content-center">
                  <img src={nodata} alt="" srcset="" />
                </div>
              }
            </>
          }

        </div>
      </div>
    </div>
  );
};

export default PastApproved;

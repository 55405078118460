import axios from 'axios'
import {
  Get_AllUser,
  LOGIN,
  deletedata,
  GetSingleUser,
  UserUpdate,
  checkpassword,
  WebinarGetAll,
  GetSingleWebinar,
  Approvewebinar,
  Declineewbinar,
  webinarDelete,
  UserUndo,
  WebinarUndo,
  updatepassword_endpoint,
  EditWabinar,
  RegisterWebinar,
  RegisterGetAll,
  ExportToExcel
} from './Routes'
import { BASE_URL } from '../Constants';



axios.defaults.baseURL = BASE_URL
const token = localStorage.getItem('token');

//  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
//   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const loginService = async (data) => {
  try {
    const response = await axios.post(LOGIN, data, {
      headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getAllUser = async (data) => {
  try {
    const response = await axios.get(Get_AllUser + data.pageNumber, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": true
      },

    })
    if (response.status === 200) {
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const deleteddata = async (data) => {
  try {
    const response = await axios.delete(deletedata + data, {
      headers: {
        Authorization: 'Bearer ' + token,
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const UndoUserData = async (data) => {
  try {
    const response = await axios.post(UserUndo + data, {}, {
      headers: {
        Authorization: 'Bearer ' + token,
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getSingleUser = async () => {
  try {
    const response = await axios.get(GetSingleUser, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const updatedata = async (data) => {
  try {
    const response = await axios.post(UserUpdate, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data'
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const PasswordCheck = async (data) => {
  try {
    const response = await axios.post(checkpassword, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
        "ngrok-skip-browser-warning": true

      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const getallwebinar = async (data) => {
  try {
    const response = await axios.post(WebinarGetAll, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'image/jpeg',
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const singleWebinar = async (data) => {
  try {
    const response = await axios.get(GetSingleWebinar + data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const approvewebinar = async (data) => {
  try {
    const response = await axios.post(Approvewebinar, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const declineewbinar = async (data) => {
  try {
    const response = await axios.post(Declineewbinar, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const DeleteWebinar = async (data) => {
  try {
    const response = await axios.delete(webinarDelete + data, {
      headers: {
        Authorization: 'Bearer ' + token,
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const UndoWebinarData = async (data) => {
  try {
    const response = await axios.post(WebinarUndo + data, {}, {
      headers: {
        Authorization: 'Bearer ' + token,
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const update_password = async (data) => {
  try {
    const response = await axios.put(updatepassword_endpoint, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const WebinarEdit = async (data) => {
  try {
    const response = await axios.post(EditWabinar, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const WebinarRegister = async (data) => {
  try {
    const response = await axios.post(RegisterWebinar, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      console.log(response.data)
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const RegisterAll = async (data) => {
  try {
    const response = await axios.post(RegisterGetAll , data,{
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": true
      },
    })
    if (response.status === 200) {
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}

export const ExportExcel = async (data) => {
  try {
    const response = await axios.post(ExportToExcel , data,{
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
        "ngrok-skip-browser-warning": true
      },
      responseType: "blob",
    })
    if (response.status === 200) {
      return {
        res: response.data
      }
    } else return response.data
  } catch (err) {
    if (err.response) throw err.response.data
    else throw err.message
  }
}


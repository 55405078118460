import React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import ModalClose from "@mui/joy/ModalClose";



export default function PeopleAddModel({ reopen, setreopen, viewData }) {

  const [formData, setFormData] = React.useState({});

  const handleClose = () => {
    setreopen(false);
  };

  const onSubmitform = (e) => {
    e.preventDefault();


  }

  const onInputChange = (evt) => {
    let { name, value } = evt.target;
    setFormData({ ...formData, [name]: value });
  };



  return (
    <>
      <React.Fragment>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={reopen}
          onClose={() => setreopen(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 650,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                borderColor: '#808191',
                border: 2,
                bgcolor: 'white',
                color: '#808191'
              }}
            />
            <div id="modal-desc" textColor="text.tertiary">
              <div className="peopleaddmodel ">
                <div className="peoplelabin ">
                  <form onSubmit={onSubmitform} onChange={onInputChange}>
                    <div className="">
                      <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="">
                            <p>First Name</p>
                            <input type="text" className="form-control" placeholder="Samriti" name="firstName" disabled value={viewData?.firstName} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 modelnamelast">
                          <div>
                            <p>Last Name</p>
                            <input type="text" className="form-control" placeholder="Sharma" name="lastName" disabled value={viewData?.lastName} />
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                          <div className="">
                            <p>Contact Number</p>
                            <input type="text" className="form-control" placeholder="+91-8506073332" disabled name="contactNumber" value={viewData?.contactNumber} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                          <div>
                            <p>Email</p>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="samritisharma@gmail.com"
                              name="email"
                              value={viewData?.email}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  col-12 mt-3 peoplelabines">
                          <p>Authority</p>

                          <ul class="ux-btn-group" >
                            <li>
                              <input type="radio" id="Checker" class="ux-btn-group-item " name="roleId" value={viewData?.roleId} checked disabled />
                              <label for="Checker">{viewData?.roleId===2?"Checker":"Maker"}</label>
                            </li>
                            {/* <li>
                              <input type="radio" id="Maker" class="ux-btn-group-item"  name="roleId" value={viewData.roleId} />
                              <label for="Maker">Maker</label>
                            </li> */}
                          </ul>
                        </div>
                        <div className="row ">
                          <div className="col-12 d-flex mt-2">
                            <div className="col-6">
                              <botton
                                className="modlecanclebtn d-flex align-items-center justify-content-center"
                                onClick={handleClose}
                              >
                                Cancel
                              </botton>
                            </div>
                            <div className="col-6">
                              <button className="modeldeletebtn" type="submit">Add</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Sheet>
        </Modal>
      </React.Fragment>
    </>
  );
}
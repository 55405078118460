import moment from "moment";
import React from "react";
import logo from "../../Assets/image/icons/book.png"
import ApprovalpandingModal from "./ApprovalpandingModal";

const ApprovedCart = (props) => {
  const [open, setOpen] = React.useState(false);
  console.log(props.data);
  return (
    <>
      <div className="card Cheacker">
        <div className="card-body Cheackerbody">
          <div className="Cheackerdata">
            <div className="d-flex justify-content-center">
            <div className="abcd">
              <div className="cheackerimg">
                <img src={props.image} alt="..." />
              </div>
              </div>
            </div>
            <div className="cheakercontent">
              <h2 className="cheackertitle">{props.Title}</h2>
              <div className="cheackerpass">
                <p className="mt-2">Leader:<span>{props.data.host}</span></p>
                <p className="mt-2">Time: <span>{props.data.webinarStartTime}- {props.data.webinarEndTime}</span></p>
                <p className="mt-2">Date: <span>{moment(props.webinarOn).format('DD-MM-YYYY')}</span></p>
                <p className="mt-2">Language:<span>{props.Language}</span></p>
              </div>
              <div className="d-flex declineapprovebtn">
                <button className="approvedbtnns" >Approve</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApprovalpandingModal setOpen={setOpen} open={open} />
    </>
  );
};

export default ApprovedCart;

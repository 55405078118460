import axios from 'axios'
import { LOGIN } from '../../Utils/Routes';
import { BASE_URL } from '../../Constants';


axios.defaults.baseURL = BASE_URL;

// Login user
const login = async (userData) => {
    const response = await axios.post(LOGIN, userData)
    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
        localStorage.setItem("token", response.data.data.accessToken);
        localStorage.setItem("refreshToken", response.data.data.refreshToken);
        localStorage.setItem("accessTokenValidity", response.data.data.accessTokenValidity);
        localStorage.setItem("refreshTokenValidity", response.data.data.refreshTokenValidity);
    }
    return response.data
}

// Logout user
const logout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('accessTokenValidity')
    localStorage.removeItem('refreshTokenValidity')
}

const authService = {
    logout,
    login,
}

export default authService